import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 引入饿了么组件库字典
import elementZH from 'element-ui/lib/locale/lang/zh-CN'
import elementEN from 'element-ui/lib/locale/lang/en'
import zh from './zh'
import en from './en'
Vue.use(VueI18n) // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || 'zh', // 初始化中文
  messages: {
    zh: {
      ...elementZH,
      ...zh
    },
    en: {
      ...elementEN,
      ...en
    }
  }
})   

export default i18n